import React, { Component } from 'react';
//import { HashRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './scss/style.scss';
import { AuthProvider } from "./context/Auth";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/auth/login/Login'));
const Register = React.lazy(() => import('./views/auth/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/auth/forgotPassword/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/auth/page404/Page404'));
const Page500 = React.lazy(() => import('./views/auth/page500/Page500'));

class App extends Component {

  render() {
    return (
      <AuthProvider>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />     
              <Route exact path="/forgot-password" name="Forgot Password Page" render={props => <ForgotPassword {...props} />} />  
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              
              <Route exact path="/change-password" name="Change Password" render={props => <TheLayout {...props} />} />
              
              <Route exact path="/dashboard" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/business-info" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/view-address" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-address" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/view-roles" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-roles" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/view-employees" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-employee" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/employee-details" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/manage-schedule" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-schedule" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/shift-trades" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-vacation" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/clock-in-out" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/shift-feedback" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/view-shift-checklist" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-shift-checklist" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/open-roles" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-announcement" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/view-announcements" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/view-hiring" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/add-hiring" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/messaging" name="Home" render={props => <TheLayout {...props} />} />
              <Route exact path="/budget-management" name="Home" render={props => <TheLayout {...props} />} />



              <Route exact path="*" name="Page 404" render={props => <Page404 {...props}/>} />
            </Switch>
            </React.Suspense>
          </Router>
    </AuthProvider>
    );
  }
}

export default App;


/*
 <Route path="/dashboard" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/business-info" name="Business Info" render={props => <TheLayout {...props} />} />
              <Route path="/business-addresses" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/add-roles" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/employees" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/manage-schedule" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/shift-trades" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/time-off-vacations" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/clock-in-out" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/shift-feedback" name="Home" render={props => <TheLayout {...props} />} />
              <Route path="/view-announcements" name="Users" render={props => <TheLayout {...props} />} />
              <Route path="/open-roles" name="Users" render={props => <TheLayout {...props} />} />
              <Route path="/messages" name="Users" render={props => <TheLayout {...props} />} />
*/