import React, { useEffect, useState } from "react";
import app from "./base.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
  )
  

  

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (user != null) {
        await app
          .firestore()
          .collection("em-users")
          .where("uid", "==", user.uid)
          .get()
          .then((snapshot) => {
            user.type = snapshot.docs[0].data().type;
            user.name = snapshot.docs[0].data().name;
          })
          .catch((err) => {
            console.log(err);
          });

        await app
          .firestore()
          .collection("em-business")
          .where("userUID", "==", user.uid)
          .get()
          .then((snapshot) => {
            user.businessID = snapshot.docs[0]?.id;
            user.businessName = snapshot.docs[0]?.data().companyName;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setCurrentUser(user);
      setPending(false);

      
    });
  }, []);
  /*
  app
    .auth()
    .signOut();
    */
  
  if (pending) {
    return (<>{ loading }</>);
  }
/*
  if (pending) {
    //return <>Loading...</>;
    return (<>
      <div height="100%"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ><Spinner animation="grow" variant="primary" /></div>
    </>);
  }
*/

  return (
    
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
