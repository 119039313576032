import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';

const app1 = {
    apiKey: "AIzaSyD5m4vptHsZ8wtBXRsx2UYENwL31zOMM8M",
    authDomain: "employee-management-da04c.firebaseapp.com",
    projectId: "employee-management-da04c",
    storageBucket: "employee-management-da04c.appspot.com",
    messagingSenderId: "769928855411",
    appId: "1:769928855411:web:95acd1523c6da9e4ad4f06",
    measurementId: "G-2SZ2V92BR5"
};
  
var app = firebase.initializeApp(app1);

  
export default app;
